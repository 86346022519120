import React, { useState, useEffect } from 'react';
import { toast , ToastContainer} from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Table, Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';


import Sidebar from '../components/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faRecycle } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';

const Admin = () => {

    const [refreshInterval, setRefreshInterval] = useState(1);
    const [votos, setVotos] = useState(null);
    const [actaABorrar, setActaABorrar] = useState(null)
    const [actas, setAactas] = useState([]);
    //const BASE_URL = 'http://localhost:3006';
    const BASE_URL = 'https://api.bdatosgeneral.xyz/carga'
    const navigate = useNavigate();
    const token = localStorage.getItem('token_2');
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
    useEffect(() => {
        getActas()
        const interval = setInterval(() => {
            getActas()
        }, refreshInterval * 60 * 1000); // Convertir minutos a milisegundos

        return () => clearInterval(interval);
    }, [refreshInterval]);

    const [showModalSearch, setShowModalSearch] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);

    // Función para abrir el modal de búsqueda
    const handleOpenModalSearch = (actaID) => {
        getVotos(actaID);
        setShowModalSearch(true);
    };

    // Función para abrir el modal de confirmación
    const handleOpenModalConfirm = (actaID) => {

        setActaABorrar(actaID)
        if (actaID != null) {
            setShowModalConfirm(true);
        }
    };

    // Función para manejar la confirmación del modal
    const handleConfirmModal = () => {
        // Aquí puedes realizar la acción que desees al confirmar el modal
        // Por ejemplo, mostrar el alert
        borrarVotos(actaABorrar);
        // Cerrar el modal de confirmación después de la acción
        setShowModalConfirm(false);
    };

    const borrarVotos = (actaID) => {
        axios.delete(BASE_URL + `/admin/votos/${actaID}`, config)
            .then((response) => {

                toast.success('Votos borrados correctamente', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                getActas()
            })
            .catch((error) => {
                handleApiError(error);
            });
    }
    const getVotos = (actaID) => {

        axios.get(BASE_URL + `/admin/votos/${actaID}`, config)
            .then((response) => {
                setVotos(response.data.votos);
                console.log(votos)
            })
            .catch((error) => {
                handleApiError(error);
            });
    }

    const getActas = () => {
        axios.get(BASE_URL + '/admin/actas', config)
            .then((response) => {

                setAactas(response.data.actas);
                console.log(response.data.actas)
            })
            .catch((error) => {
                handleApiError(error);
            });

    };

    const handleApiError = (error) => {
        if (error.message === 'Network Error') {
            // Handle the connection refused error here
            toast.error('Sin conexion con el servidor', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.error('Connection refused. The server may not be running or is unreachable.');
        } else {


            switch (error.response && error.response.status) {
                case 401:
                    navigate('/login');
                    break;
                case 403:
                    toast.error('Acceso denegado', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                case 500:
                    toast.error('Error interno del servidor ' + error.mensaje, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                default:
                    console.error(error);
                    break;
            }
        }
    };

    const getEstado = (Cargada, Error) => {
        if (Cargada === 'S' && Error !== 'S') { return <Badge bg="success">Cargada</Badge> }
        if (Error === 'S') { return <Badge bg="danger">Error en carga</Badge> }
    }
    const setLista = (lista, tipo) => {
        if (tipo === 'AFIRMATIVO') return lista;
        return <b>{tipo}</b>
    }

    return (
        <>
            <Sidebar />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container fluid>
                <Row>
                    <p></p>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>Mesas escrutadas
                            </Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Acta ID</th>
                                            <th>Mesa</th>
                                            <th>Votantes</th>
                                            <th>Numero de electores</th>
                                            <th>Circuito</th>
                                            <th>Establecimiento</th>
                                            <th>Estado</th>
                                            <th style={{ width: 200 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        {actas && actas.map((item, i) => (
                                            <tr key={i}>
                                                <td>{item.ActaID}</td>
                                                <td>{item.NumeroMesa}</td>
                                                <td>{item.Votos}</td>
                                                <td>{item.NumeroVotantes}</td>

                                                <td>{item.NumeroCircuito}</td>
                                                <td>{item.Establecimiento}</td>
                                                <td>{getEstado(item.Cargada, item.Error)}</td>
                                                <td>
                                                    <Row>
                                                        <Col md={3}></Col>
                                                        <Col md={3}>
                                                            <Button variant="primary" onClick={(e) => { handleOpenModalSearch(item.ActaID) }}>
                                                                <FontAwesomeIcon icon={faSearch} />
                                                            </Button>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Button variant="warning" onClick={(e) => { handleOpenModalConfirm(item.ActaID) }}>
                                                                <FontAwesomeIcon icon={faRecycle} />
                                                            </Button>
                                                        </Col>
                                                        <Col md={3}></Col>
                                                    </Row>


                                                    {/* Botón de confirmación */}

                                                </td>
                                            </tr>
                                        ))}


                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

                <Modal show={showModalSearch} onHide={() => setShowModalSearch(false)} fullscreen={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>Votos del acta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Contenido del modal de búsqueda, puede ser una tabla */}
                        {/* Ejemplo de tabla en el modal */}
                        <Card>
                            <Card.Header>Votos por Lista
                            </Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Lista</th>
                                            <th>Presidente</th>
                                          


                                        </tr>
                                    </thead>
                                    <tbody>


                                        {votos && votos.map((item, i) => (
                                            <tr key={i}>
                                                <td>{setLista(item.Nombre, item.Tipo)}</td>
                                                <td><b>{item.Presidente_vice}</b>  </td>
                                               
                                            </tr>

                                        ))}




                                    </tbody>
                                </Table>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>

                                        </tr>
                                    </thead>
                                    <tbody>





                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalSearch(false)}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de confirmación */}
                <Modal show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reiniciar ACTA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Al confirmar se van a borrar todos los votos que se puedieran haber cargado para esta acta dejandola lista para una carga de cero
                        <p></p>
                        ¿Estás seguro de que deseas realizar esta acción?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalConfirm(false)}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={handleConfirmModal}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>

    );
};

export default Admin;
