import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';

import Login from './pages/Login';
import Home from './pages/Home';
import ProtectedRoute from './components/ProtectedRoute';
import 'bootstrap/dist/css/bootstrap.min.css';

import Admin from './pages/Admin';

const App = () => {


  return (
    <AuthProvider>

        <Router>

          <Routes>

            <Route path="/" element={<Login />} />

            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<ProtectedRoute />}>
              <Route path="/admin" element={<Admin />} />
            </Route>
            <Route path="/home" element={<ProtectedRoute />}>
              <Route path="/home" element={<Home />} />
            </Route>
            <Route path="*" element={<h1>Pagina inexistente</h1>} />
          </Routes>
        </Router>
 
    </AuthProvider >

  );
};

export default App;
