import React, { useState, useContext, useRef, useEffect } from 'react';
import { Offcanvas, Navbar, Nav, Accordion } from 'react-bootstrap';
import { FiPower } from 'react-icons/fi';
import '../css/Menu.css'; // Importa el archivo CSS aquí
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';


const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const menuRef = useRef(null);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { ability } = useContext(AuthContext);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleSidebar = () => {
    setExpanded(!expanded);
  };
  const handleLogOut = () => {
    logout()
    navigate('/login')
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  

  const ir = (path) => {
    navigate(path)
  }
  return (
    <>
      <Navbar bg="primary" variant="dark" >
        <Navbar.Brand style={{ color: "black" }}>Actas</Navbar.Brand>
        <Navbar.Toggle onClick={handleToggleSidebar} />
        <Navbar.Collapse className="justify-content-end">
          <Nav >
            <Nav.Link onClick={handleToggleSidebar} style={{ color: "black" }}>Menu</Nav.Link>
            <Nav.Link onClick={handleLogOut} style={{ color: "black" }}><FiPower /></Nav.Link>
          </Nav>

        </Navbar.Collapse>
      </Navbar>


      <Offcanvas placement="start" show={expanded} onHide={() => { }} className="custom-offcanvas">
        <Offcanvas.Body ref={menuRef}>
          <Nav className="flex-column">
            {/* <h1>Nombre apellido</h1> */}
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <Accordion.Header >
                  {expanded && <span>Opciones</span>}
                </Accordion.Header>
                <Accordion.Body>
                  <Nav className="flex-column">
                    {ability&& ability.can('borrar', 'Votos') &&
                   
                        <Nav.Item>
                          <Nav.Link onClick={() => { ir('/admin') }} >
                            {expanded && <span>Admin</span>}
                          </Nav.Link>
                        </Nav.Item> }
                    <Nav.Item>

                      <Nav.Link onClick={() => { ir('/home') }} >
                        {expanded && <span>Carga</span>}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={handleLogOut}>
                        {expanded && <span>Log Out</span>}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={handleToggleSidebar} >
                        {expanded && <span>Cerrar</span>}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>


          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
