import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.css'; // Import the CSS file you created
import loadingGif from '../css/giphy.gif'; // Replace 'path/to/loading.gif' with the actual path to your loading gif file.
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'; // Ruta al archivo CSS con los estilos

export function Migrantes(props) {
    const [data, setData] = useState(Array.from({ length: 100 }, () => []));
    const [isLoading, setIsLoading] = useState(false); // S
    const [columnErrors, setColumnErrors] = useState([]);
    const [columnSuccess, setColumnSuccess] = useState([]);
    const [listas, setListas] = useState([]);
    const [dataModal, setDataModal] = useState([]);
    const inputsRef = useRef([]);
    const [inputValues, setInputValues] = useState({});

    const [showModal, setShowModal] = useState(false); // Estado para controlar la visibilidad del modal

    const handleModalClose = () => setShowModal(false); // Función para cerrar el modal
    const handleModalShow = () => setShowModal(true); //

    const navigate = useNavigate();
    //const BASE_URL = 'http://localhost:3006';
    const BASE_URL = 'https://api.bdatosgeneral.xyz/carga'
    const token = localStorage.getItem('token_2');
    const config = {
        headers: { 'Authorization': `Bearer ${token}` }
    };
    const getColumnHeader = col => {
        switch (col) {
            case 0:
                return 'Gobernador/Vicegobernador';
            case 1:
                return 'Senador Provincial';
            case 2:
                return 'Intendente/Concejal';
            default:
                return '';
        }
    };
    const [typingTimeout, setTypingTimeout] = useState(null);

    useEffect(() => {
  
  
      // Lógica que deseas ejecutar cuando el usuario haya terminado de ingresar
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      const newTimeout = setTimeout(() => {
        // Lógica que deseas ejecutar cuando el usuario haya terminado de ingresar
        verificarTotalDeVotos()
       
      }, 1000);
      setTypingTimeout(newTimeout);
      autoSumaAgrupacion()
      autoSumaTotalPorColumna()

    }, [inputValues])

    const cellStyle = {
        padding: '5px',
        border: '1px solid black', // Agregar bordes de 1px en cada celda
        textAlign: 'center', // Centrar el contenido de la celda
        verticalAlign: 'middle', // Centrar verticalmente el contenido de la celda
    };

    const footerCellStyle = {
        ...cellStyle, // Mantener los mismos estilos de las celdas anteriores
        fontWeight: 'bold', // Aplicar negrita a los totales de las columnas
    };


  const faltantesStyle = {
    padding: '5px',
    textAlign: 'center', // Centrar el contenido de la celda
    verticalAlign: 'middle',  // Mantener los mismos estilos de las celdas anteriores
    fontStyle: 'italic',
    border: 'none'
  };

    const renderTable = () => {

        const rows = [];
        const footerRows = [];

        for (let i = 0; i < listas.length; i++) {
            const row = [];

            for (let j = 0; j < 3; j++) {
                const value = data[i] && data[i][j] ? data[i][j] : '';

                const cellStyle = {};

                if (columnErrors.includes(j)) {
                    cellStyle.color = 'red';
                } else if (columnSuccess.includes(j)) {
                    cellStyle.color = 'green';
                }
                const habilitadoValue = listas[i].habilitado[j + 5][Object.keys(listas[i].habilitado[j + 5])[0]];
                const isDisabled = habilitadoValue === 'N';

                row.push(
                    <td key={`cell-${i}-${j}`} style={{
                        padding: '5px',
                        border: '1px solid black', // Agregar bordes de 1px en cada celda
                        textAlign: 'center', // Centrar el contenido de la celda
                        verticalAlign: 'middle', // Centrar verticalmente el contenido de la celda
                        ...cellStyle, // Mantener los estilos de color en caso de error o éxito
                    }}>
                        <input
                            type='number'
                            value={value}
                            disabled={isDisabled}
                            onChange={e => handleInputChange(e, i, j)}
                            onKeyDown={e => handleKeyDown(e, i, j)}
                            onClick={e => handleClickInput(e, i, j)}
                            ref={input => {
                                if (!inputsRef.current[i]) {
                                    inputsRef.current[i] = [];
                                }
                                inputsRef.current[i][j] = input;
                            }}
                            min={0}
                            max={999}
                            style={{
                                ...cellStyle,
                                backgroundColor: isDisabled ? 'black' : 'white', // Pintar el input de negro cuando esté deshabilitado
                                color: isDisabled ? 'white' : cellStyle.color // Cambiar el color de texto a blanco cuando esté deshabilitado
                            }}
                        />
                    </td>
                );
            }

            if (i === listas.length) {
                rows.push(
                    <tr key={`row-${i}`}>
                        <td>Totales</td>
                        {row}
                    </tr>
                );
            } else {
                rows.push(
                    <tr key={`row-${i}`} className={highlightedRow === i ? 'highlighted' : ''}>
                        <td style={{
                            fontSize: '12px', border: '1px solid black', // Agregar bordes de 1px en cada celda
                            textAlign: 'center', // Centrar el contenido de la celda
                            verticalAlign: 'middle',
                        }}>N</td>
                        <td style={{
                            fontSize: '12px', border: '1px solid black', // Agregar bordes de 1px en cada celda
                            textAlign: 'center', // Centrar el contenido de la celda
                            verticalAlign: 'middle',
                        }}>{listas[i].Partido}</td>
                        <td style={{
                            fontSize: '12px', border: '1px solid black', // Agregar bordes de 1px en cada celda
                            textAlign: 'center', // Centrar el contenido de la celda
                            verticalAlign: 'middle',
                        }}>{listas[i].Nombre}</td>
                        {row}
                    </tr>
                );
            }
        }

        footerRows.push(
            <tr key={`row-${listas.length}`} style={footerCellStyle}>
                <td colSpan="3">TOTAL VOTOS AGRUPACIONES POLITICAS</td>
                {/* Agregar las celdas de los totales de votos por agrupaciones políticas */}
                {Array.from({ length: 3 }).map((_, j) => (
                    <td key={`cell-total-agrupaciones-${j}`} style={footerCellStyle}>
                        <input
                            type="number"
                            value={data[listas.length] && data[listas.length][j] ? data[listas.length][j] : ''}
                            onChange={(e) => handleInputChange(e, listas.length, j)}
                            onKeyDown={(e) => handleKeyDown(e, listas.length, j)}
                            ref={(input) => {
                                if (!inputsRef.current[listas.length]) {
                                    inputsRef.current[listas.length] = [];
                                }
                                inputsRef.current[listas.length][j] = input;
                            }}
                            min={0}
                            max={999}
                        />
                    </td>
                ))}
            </tr>,
            // Agregar las filas restantes aquí con sus respectivos nombres
            <tr key={`row-${listas.length + 1}`} style={footerCellStyle} className={highlightedRow === listas.length + 1 ? 'highlighted' : ''}>
                <td colSpan="3">VOTOS NULOS</td>
                {/* Agregar las celdas para los votos nulos */}
                {Array.from({ length: 3 }).map((_, j) => (
                    <td key={`cell-votos-nulos-${j}`} style={footerCellStyle}>
                        <input
                            type="number"
                            value={data[listas.length + 1] && data[listas.length + 1][j] ? data[listas.length + 1][j] : ''}
                            onChange={(e) => handleInputChange(e, listas.length + 1, j)}
                            onKeyDown={(e) => handleKeyDown(e, listas.length + 1, j)}
                            onClick={(e)=>handleClickInput(e, listas.length + 1, j)}
                            ref={(input) => {
                                if (!inputsRef.current[listas.length + 1]) {
                                    inputsRef.current[listas.length + 1] = [];
                                }
                                inputsRef.current[listas.length + 1][j] = input;
                            }}
                            min={0}
                            max={999}
                        />
                    </td>
                ))}
            </tr>,
            <tr key={`row-${listas.length + 2}`} style={footerCellStyle} className={highlightedRow === listas.length + 2 ? 'highlighted' : ''}>
                <td colSpan="3">VOTOS RECURRIDOS QUE SE REMITEN EN SOBRE Nro.3</td>
                {/* Agregar las celdas para los votos recurridos */}
                {Array.from({ length: 3 }).map((_, j) => (
                    <td key={`cell-votos-recurridos-${j}`} style={footerCellStyle}>
                        <input
                            type="number"
                            value={data[listas.length + 2] && data[listas.length + 2][j] ? data[listas.length + 2][j] : ''}
                            onChange={(e) => handleInputChange(e, listas.length + 2, j)}
                            onKeyDown={(e) => handleKeyDown(e, listas.length + 2, j)}
                            onClick={(e)=>handleClickInput(e, listas.length + 2, j)}
                            ref={(input) => {
                                if (!inputsRef.current[listas.length + 2]) {
                                    inputsRef.current[listas.length + 2] = [];
                                }
                                inputsRef.current[listas.length + 2][j] = input;
                            }}
                            min={0}
                            max={999}
                        />
                    </td>
                ))}
            </tr>,
            <tr key={`row-${listas.length + 3}`} style={footerCellStyle} className={highlightedRow === listas.length + 3 ? 'highlighted' : ''}>
                <td colSpan="3">VOTOS DE IDENTIDAD IMPUGNADA QUE SE REMITEN EN SOBRE Nro.3</td>
                {/* Agregar las celdas para los votos de identidad impugnada */}
                {Array.from({ length: 3 }).map((_, j) => (
                    <td key={`cell-votos-identidad-impugnada-${j}`} style={footerCellStyle}>
                        <input
                            type="number"
                            value={data[listas.length + 3] && data[listas.length + 3][j] ? data[listas.length + 3][j] : ''}
                            onChange={(e) => handleInputChange(e, listas.length + 3, j)}
                            onKeyDown={(e) => handleKeyDown(e, listas.length + 3, j)}
                            onClick={(e)=>handleClickInput(e, listas.length + 3, j)}
                            ref={(input) => {
                                if (!inputsRef.current[listas.length + 3]) {
                                    inputsRef.current[listas.length + 3] = [];
                                }
                                inputsRef.current[listas.length + 3][j] = input;
                            }}
                            min={0}
                            max={999}
                        />
                    </td>
                ))}
            </tr>,
            <tr key={`row-${listas.length + 4}`} style={footerCellStyle} className={highlightedRow === listas.length + 4 ? 'highlighted' : ''}>

                <td colSpan="3">VOTOS DEL COMANDO ELECTORAL QUE SE REMITEN EN EL BOLSIN</td>
                {/* Agregar las celdas para los votos del comando electoral */}
                {Array.from({ length: 3 }).map((_, j) => (
                    <td key={`cell-votos-comando-electoral-${j}`} style={footerCellStyle}>
                        <input
                            type="number"
                            value={data[listas.length + 4] && data[listas.length + 4][j] ? data[listas.length + 4][j] : ''}
                            onChange={(e) => handleInputChange(e, listas.length + 4, j)}
                            onKeyDown={(e) => handleKeyDown(e, listas.length + 4, j)}
                            onClick={(e)=>handleClickInput(e, listas.length + 4, j)}
                            ref={(input) => {
                                if (!inputsRef.current[listas.length + 4]) {
                                    inputsRef.current[listas.length + 4] = [];
                                }
                                inputsRef.current[listas.length + 4][j] = input;
                            }}
                            min={0}
                            max={999}
                        />
                    </td>
                ))}
            </tr>,
            <tr key={`row-${listas.length + 5}`} style={footerCellStyle} className={highlightedRow === listas.length + 5 ? 'highlighted' : ''}>
                <td colSpan="3">VOTOS EN BLANCO</td>
                {/* Agregar las celdas para los votos en blanco */}
                {Array.from({ length: 3 }).map((_, j) => (
                    <td key={`cell-votos-en-blanco-${j}`} style={footerCellStyle}>
                        <input
                            type="number"
                            value={data[listas.length + 5] && data[listas.length + 5][j] ? data[listas.length + 5][j] : ''}
                            onChange={(e) => handleInputChange(e, listas.length + 5, j)}
                            onKeyDown={(e) => handleKeyDown(e, listas.length + 5, j)}
                            onClick={(e)=>handleClickInput(e, listas.length + 5, j)}
                            ref={(input) => {
                                if (!inputsRef.current[listas.length + 5]) {
                                    inputsRef.current[listas.length + 5] = [];
                                }
                                inputsRef.current[listas.length + 5][j] = input;
                            }}
                            min={0}
                            max={999}
                        />
                    </td>
                ))}
            </tr>,
            <tr key={`row-${listas.length + 7}`} style={faltantesStyle} className={highlightedRow === listas.length + 7 ? 'highlighted' : ''}>
            <td colSpan="3">Votos en blanco faltantes</td>
            {/* Agregar las celdas para los votos en blanco */}
            {Array.from({ length: 3 }).map((_, j) => (
              <td key={`cell-votos-en-blanco-${j}`} style={faltantesStyle}>
                <input
                  type="number"
                  value={data[listas.length + 7] && data[listas.length + 7][j] ? data[listas.length + 7][j] : '0'}
                  onChange={(e) => handleInputChange(e, listas.length + 7, j)}
                  onKeyDown={(e) => handleKeyDown(e, listas.length + 7, j)}
                  
                  ref={(input) => {
                    if (!inputsRef.current[listas.length + 7]) {
                      inputsRef.current[listas.length + 7] = [];
                    }
                    inputsRef.current[listas.length + 7][j] = input;
                  }}
                  min={0}
                  max={999}
                  disabled = {true}
                  style={faltantesStyle}
                />
              </td>
            ))}
          </tr>,
            <tr key={`row-${listas.length + 6}`} style={footerCellStyle} className={highlightedRow === listas.length + 6 ? 'highlighted' : ''}>
                <td colSpan="3">TOTAL POR COLUMNAS (*)</td>
                {Array.from({ length: 3 }).map((_, j) => (
                    <td key={`cell-total-column-${j}`} style={footerCellStyle}>
                        <input
                            type="number"
                            className="inputTotal"
                            value={data[listas.length + 6] && data[listas.length + 6][j] ? data[listas.length + 6][j] : ''}
                            onChange={(e) => handleInputChange(e, listas.length + 6, j)}
                            onKeyDown={(e) => handleKeyDown(e, listas.length + 6, j)}
                            onClick={(e)=>handleClickInput(e, listas.length + 6, j)}
                            ref={(input) => {
                                if (!inputsRef.current[listas.length + 6]) {
                                    inputsRef.current[listas.length + 6] = [];
                                }
                                inputsRef.current[listas.length + 6][j] = input;
                            }}
                            min={0}
                            max={999}
                        />
                    </td>
                ))}
            </tr>
        );

        return [...rows, ...footerRows];
    };





    function isArrayAllZeros(arr) {
        // Utilizamos el método every() para verificar si todos los elementos del array son iguales a cero.
        return arr.every((element) => element === 0);
    }


    const verificarTotalDeVotos = () => {

        const votos = props.votos;
        let error = false
        const rowTotalPorColumna = [];
        const columnCount = 3; // Número de columnas en tu objeto

        for (let j = 0; j < columnCount; j++) {
            let totalColumna = 0;

            for (let i = 0; i < listas.length + 6; i++) {
                // Excluir las filas listas.length + 1 y listas.length + 6
                if (i === listas.length || i === listas.length) {
                    continue;
                }

                const value = data[i] && data[i][j] !== undefined ? data[i][j] : 0;
                totalColumna += value;
            }

            rowTotalPorColumna.push(totalColumna);
        }

        const columnasConError = [];

        for (let columna = 0; columna < rowTotalPorColumna.length; columna++) {
            const sumaColumna = rowTotalPorColumna[columna];

            if (sumaColumna > votos) {
                columnasConError.push(columna);
                toast.error('En ' + getColumnHeader(columna) + ' se supero el maximo de votos para este acta (' + votos + ')', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                error = true;
            }
        }
        setColumnErrors(columnasConError)
        return error;
    }


    function isArrayAllZeros(arr) {
        // Utilizamos el método every() para verificar si todos los elementos del array son iguales a cero.
        return arr.every((element) => element === 0);
    }

    const autoSumaAgrupacion = () => {


        const rowTotalPorColumna = [];
        const columnCount = 3; // Número de columnas en tu objeto
        const filaTotal = []
        for (let j = 0; j < columnCount; j++) {
            const value = data[listas.length] && data[listas.length][j] !== undefined ? data[listas.length][j] : 0;
            filaTotal.push(value)
        }

        for (let j = 0; j < columnCount; j++) {
            let totalColumna = 0;

            for (let i = 0; i < listas.length; i++) {
                // Excluir las filas listas.length + 1 y listas.length + 6
                const value = data[i] && data[i][j] !== undefined ? data[i][j] : 0;
                totalColumna += value;
            }

            rowTotalPorColumna.push(totalColumna);
        }

        console.log(rowTotalPorColumna)


        for (let columna = 0; columna < rowTotalPorColumna.length; columna++) {
            data[listas.length][columna] = rowTotalPorColumna[columna]
        }

    }


  const autoSumaTotalPorColumna = () =>{
    let error = false
    const rowTotalPorColumna = [];
    const columnCount = 4; // Número de columnas en tu objeto

   
    for (let j = 0; j < columnCount; j++) {
      let totalColumna = 0;
      console.log(listas.length)

      for (let i = listas.length ; i < listas.length+6; i++) {
        // Excluir las filas listas.length + 1 y listas.length + 6
        const value = data[i] && data[i][j] !== undefined ? data[i][j] : 0;


        totalColumna += value;
      }

      rowTotalPorColumna.push(totalColumna);
    }

    console.log(rowTotalPorColumna)

    for (let columna = 0; columna < rowTotalPorColumna.length; columna++) {
      data[listas.length+6][columna] = rowTotalPorColumna[columna]
    }


    for (let columna = 0; columna < rowTotalPorColumna.length; columna++) {
      data[listas.length+7][columna] = props.votos - rowTotalPorColumna[columna]
    }
    
  }

    const verificarTotalVotantes = () => {
        //aca chequeo que el total de votantes sea igual en todas las columnas de total de votos

        const filaTotalColumna = [];
        for (let j = 0; j < 3; j++) {

            const value = data[listas.length + 6] && data[listas.length + 6][j] !== undefined ? data[listas.length + 6][j] : 0;
            filaTotalColumna.push(value);
        }
        let error = false

        console.log(filaTotalColumna)

        const columnasConError = [];

        for (let columna = 0; columna < filaTotalColumna.length; columna++) {

            if (parseInt(filaTotalColumna[columna]) !== parseInt(props.votos)) {
                columnasConError.push(columna);
                toast.error('En ' + getColumnHeader(columna) + ' [Total por columnas] no coincide con el total de votos. Ingresaste: ' + filaTotalColumna[columna] + ' y hay ' + props.votos + ' votantes', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                error = true;
            }
        }

        setColumnErrors(columnasConError)
        return error;

    }


    const verificarTotalAgrupaciones = () => {

        let error = false
        const rowTotalPorColumna = [];
        const columnCount = 3; // Número de columnas en tu objeto
        const filaTotal = []
        for (let j = 0; j < columnCount; j++) {
            const value = data[listas.length] && data[listas.length][j] !== undefined ? data[listas.length][j] : 0;
            filaTotal.push(value)
        }

        for (let j = 0; j < columnCount; j++) {
            let totalColumna = 0;

            for (let i = 0; i < listas.length; i++) {
                // Excluir las filas listas.length + 1 y listas.length + 6
                const value = data[i] && data[i][j] !== undefined ? data[i][j] : 0;
                totalColumna += value;
            }

            rowTotalPorColumna.push(totalColumna);
        }


        const columnasConError = [];

        for (let columna = 0; columna < rowTotalPorColumna.length; columna++) {
            const sumaColumna = rowTotalPorColumna[columna];

            if (sumaColumna !== filaTotal[columna]) {
                columnasConError.push(columna);
                toast.error('En ' + getColumnHeader(columna) + ' [Total votos agrupaciones politicas] incorrecto', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                error = true;
            }
        }
        setColumnErrors(columnasConError)
        return error;

    }

    const verificarTotalColumnas = () => {
        const filaTotalColumna = [];
        for (let j = 0; j < 3; j++) {

            const value = data[listas.length + 6] && data[listas.length + 6][j] !== undefined ? data[listas.length + 6][j] : 0;
            filaTotalColumna.push(value);
        }
        let error = false
        const rowTotalPorColumna = [];
        const columnCount = 3; // Número de columnas en tu objeto

        for (let j = 0; j < columnCount; j++) {
            let totalColumna = 0;

            for (let i = listas.length; i < listas.length + 6; i++) {
                // Excluir las filas listas.length + 1 y listas.length + 6
                const value = data[i] && data[i][j] !== undefined ? data[i][j] : 0;
                totalColumna += value;
            }

            rowTotalPorColumna.push(totalColumna);
        }

        const columnasConError = [];

        for (let columna = 0; columna < rowTotalPorColumna.length; columna++) {
            const sumaColumna = rowTotalPorColumna[columna];

            if (sumaColumna !== filaTotalColumna[columna]) {
                columnasConError.push(columna);
                toast.error('En ' + getColumnHeader(columna) + ' [Total por columnas] no coincide con el total de votos de agrupaciones más NULOS, RECURRIDOS, IMPUGANOS,COMANDO ELECTORAL Y/O EN BLANCO', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                error = true;
            }
        }

        setColumnErrors(columnasConError)
        return error;

    }


    const verificarSinVoto = () => {
        let error = false
        const filaTotal = []
        const filaColumnaTotal = []
        const columnCount = 3;
        for (let j = 0; j < columnCount; j++) {
            const value = data[listas.length] && data[listas.length][j] !== undefined ? data[listas.length][j] : 0;
            filaTotal.push(value)
        }

        for (let j = 0; j < columnCount; j++) {
            const value = data[listas.length + 6] && data[listas.length + 6][j] !== undefined ? data[listas.length + 6][j] : 0;
            filaColumnaTotal.push(value)
        }

        if (isArrayAllZeros(filaTotal) || isArrayAllZeros(filaColumnaTotal)) {
            toast.error('Acta sin votos', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            error = true;
        }
        return error
    }

    const verify = (form) => {
        if (verificarTotalAgrupaciones()) return false;
        if (verificarTotalDeVotos()) return false;
        if (verificarTotalColumnas()) return false;
        if (verificarSinVoto()) return false;
        if (verificarTotalVotantes()) return false;

        return true; // Si no hay discrepancia, retornar true
    };

    const [highlightedRow, setHighlightedRow] = useState(null);
    const handleClickInput = (e, row, col) => {
        setHighlightedRow(row);
    }

    // const handleKeyDown = (e, row, col) => {
    //     const maxRows = listas.length + 6;
    //     let nextRow = row;
    //     let nextCol = col;

    //     if (e.key === 'ArrowUp' && row > 0) {
    //       e.preventDefault();
    //       nextRow = row - 1;
    //     } else if (e.key === 'ArrowDown' && row < maxRows) {
    //       e.preventDefault();
    //       nextRow = row + 1;
    //     } else if (e.key === 'ArrowLeft' && col > 0) {
    //       e.preventDefault();
    //       nextCol = col - 1;
    //     } else if (e.key === 'ArrowRight' && col < 2) {
    //       e.preventDefault();
    //       nextCol = col + 1;
    //     }

    //     // Loop until we find a focusable input in the desired direction
    //     while (nextRow !== row || nextCol !== col) {
    //         console.log(inputsRef.current[nextRow][nextCol].disabled)
    //       if (!inputsRef.current[nextRow][nextCol].disabled) {
    //         inputsRef.current[nextRow][nextCol].focus();
    //         break;
    //       }

    //       if (e.key === 'ArrowUp' && nextRow > 0) {
    //         nextRow--;
    //       } else if (e.key === 'ArrowDown' && nextRow < maxRows) {
    //         nextRow++;
    //       } else if (e.key === 'ArrowLeft' && nextCol > 0) {
    //         nextCol--;
    //       } else if (e.key === 'ArrowRight' && nextCol < 2) {
    //         nextCol++;
    //       } else {
    //         // No more focusable inputs in the desired direction
    //         break;
    //       }
    //     }
    //   };

    const handleKeyDown = (e, row, col) => {
        const maxRows = listas.length + 6;
        let nextRow = row;
        let nextCol = col;

        // Verificar si se presionó una tecla de flecha
        const isArrowKey = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key);

        if (isArrowKey) {
            e.preventDefault(); // Evitar cambios en el valor cuando se presiona una tecla de flecha

            if (e.key === 'ArrowUp' && row > 0) {
                nextRow = row - 1;
            } else if (e.key === 'ArrowDown' && row < maxRows) {
                nextRow = row + 1;
            } else if (e.key === 'ArrowLeft' && col > 0) {
                nextCol = col - 1;
            } else if (e.key === 'ArrowRight' && col < 2) {
                nextCol = col + 1;
            }

            // Loop hasta encontrar un input enfocable en la dirección deseada
            while (nextRow !== row || nextCol !== col) {
                if (!inputsRef.current[nextRow][nextCol].disabled) {
                    inputsRef.current[nextRow][nextCol].focus();
                    setHighlightedRow(nextRow);
                    break;
                }

                if (e.key === 'ArrowUp' && nextRow > 0) {
                    nextRow--;
                } else if (e.key === 'ArrowDown' && nextRow < maxRows) {
                    nextRow++;
                } else if (e.key === 'ArrowLeft' && nextCol > 0) {
                    nextCol--;
                } else if (e.key === 'ArrowRight' && nextCol < 2) {
                    nextCol++;
                } else {
                    // No hay más inputs enfocables en la dirección deseada
                    break;
                }
            }
        }
    };
    const handleWheel = (e) => {
        if (e.target.type === 'number') {
            e.preventDefault();
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // Resto del código para obtener formData y verificar totales...

        // Abrir el modal de confirmación antes de realizar el handleSubmit

        const specialRows = ["TOTAL VOTOS AGRUPACIONES POLITICAS", "VOTOS NULOS", "VOTOS RECURRIDOS QUE SE REMITEN EN SOBRE Nro.3", "VOTOS DE IDENTIDAD IMPUGNADA QUE SE REMITEN EN SOBRE Nro.3", "VOTOS DEL COMANDO ELECTORAL QUE SE REMITEN EN EL BOLSIN", "VOTOS EN BLANCO", "TOTAL POR COLUMNAS (*)"];

        const formData = [];

        specialRows.forEach((rowName, index) => {
            const row = data[listas.length + index];
            const votos = [];
            for (let j = 0; j < 3; j++) {

                const value = row && row[j] !== undefined ? row[j] : 0;
                votos.push({
                    columna: j,
                    votos: value,
                });
            }
            formData.push({
                listaID: null,
                listaNombre: rowName,
                votos // Obtener el tipo correspondiente para la fila actual
            });
        });
        setDataModal(formData)
        handleModalShow();
    };


    const handleActualSubmit = (e) => {
        e.preventDefault();
        const formData = [];
        setIsLoading(true);
        handleModalClose()

        // Filas correspondientes a "Votos Nulos", "Votos Recurridos que se remiten en sobre Nro.3",
        // "Votos de identidad impugnada que se remiten en sobre Nro.3", "Votos del Comando Electoral que se remiten en el bolsín"
        // y "Votos en Blanco"
        const specialRows = ['Votos Nulos', 'Votos Recurridos que se remiten en sobre Nro.3', 'Votos de identidad impugnada que se remiten en sobre Nro.3', 'Votos del Comando Electoral que se remiten en el bolsín', 'Votos en Blanco'];

        // Tipos correspondientes para cada fila de specialRows
        const specialTypes = ['NULO', 'RECURRIDO', 'IMPUGNADO', 'COMANDO_ELECTORAL', 'BLANCO'];

        listas.forEach((lista) => {
            const listaID = lista.ListaID;
            const listaNombre = lista.Nombre;
            const votos = [];
            for (let j = 0; j < 3; j++) {

                const value = inputValues[listaID] && inputValues[listaID][j] !== undefined ? inputValues[listaID][j] : 0;
                votos.push({
                    columna: j,
                    votos: value,
                });
            }
            formData.push({
                listaID,
                listaNombre,
                votos,
                tipo: 'AFIRMATIVO',
            });
        });

        // Agregar las filas correspondientes a "Votos Nulos", "Votos Recurridos que se remiten en sobre Nro.3",
        // "Votos de identidad impugnada que se remiten en sobre Nro.3", "Votos del Comando Electoral que se remiten en el bolsín"
        // y "Votos en Blanco"
        specialRows.forEach((rowName, index) => {
            const row = data[listas.length + index + 1];
            const votos = [];
            for (let j = 0; j < 3; j++) {

                const value = row && row[j] !== undefined ? row[j] : 0;
                votos.push({
                    columna: j,
                    votos: value,
                });
            }
            formData.push({
                listaID: null,
                listaNombre: rowName,
                votos,
                tipo: specialTypes[index], // Obtener el tipo correspondiente para la fila actual
            });
        });

        if (verify(formData)) {

            axios.post(BASE_URL + '/votos/migrantes', { Acta: props.acta, votos: formData, votantes: props.votos }, config)
                .then((data) => {
                    setIsLoading(false);
                    props.reiniciar()
                    toast.success('¡Acta cargada exitosamente!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
                .catch((error) => {
                    handleApiError(error);

                });

        }
        else {

            setIsLoading(false);
        }
    };


    const handleApiError = (error) => {
        setIsLoading(false);
        if (error.message === 'Network Error') {
            // Handle the connection refused error here
            toast.error('Sin conexion con el servidor', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.error('Connection refused. The server may not be running or is unreachable.');
        } else {


            switch (error.response && error.response.status) {
                case 401:
                    navigate('/login');
                    break;
                case 403:
                    toast.error('Acceso denegado', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                case 500:
                    toast.error('Error interno del servidor ' + error.response.data.mensaje, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    break;
                default:
                    console.error(error);
                    break;
            }
        }
    };

    useEffect(() => {
        axios.get(BASE_URL + '/listas', config)
            .then((response) => {
                const { data: { listas } } = response;
                setListas(listas);

                const initialValues = {};
                listas.forEach((lista, index) => {
                    const listaID = lista.ListaID;
                    const valores = lista.map((valor, i) => ({
                        key: i + 1,
                        nombreColumna: getColumnHeader(i),
                        votos: valor,
                    }));
                    initialValues[listaID] = valores;
                });
                setInputValues(initialValues);
                setData(Array.from({ length: listas.length + 6 }, () => []))

            })
            .catch((error) => handleApiError(error));
    }, []);

    const handleInputChange = (e, row, col) => {
        const value = e.target.value.trim() !== '' ? parseInt(e.target.value) : 0;

        setData((prevData) => {
            const newData = [...prevData];
            newData[row] = newData[row] || [];
            newData[row][col] = value;
            return newData;
        });

        setColumnErrors([]);
        setColumnSuccess([]);

        // Actualizar el estado inputValues con los nuevos valores y valores null para las columnas restantes
        setInputValues((prevInputValues) => {
            const newList = { ...prevInputValues };
            const listaID = listas[row]?.ListaID || null; // Establecer listaID como null si la lista no existe
            const valores = newList[listaID] || Array(3).fill(0); // Rellenar con 0 si no existen valores
            valores[col] = value;
            newList[listaID] = valores;
            return newList;
        });
    };



    // ... (handleKeyDown, handleSubmit, handleApiError, getColumnHeader, renderTable, generateTestData, verifyTotals)

    return (
        <Container fluid>
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(255, 255, 255, 0.8)', // Transparent white background
                    }}
                >
                    <img src={loadingGif} alt="Loading" />
                </div>
            )}
            <Row>
                <Col md={12} xs={12}>
                    <form onSubmit={handleSubmit}>
                        <table bordered onWheel={handleWheel} >
                            <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#6c757d' }}>
                                <tr style={{
                                    fontSize: '13px', border: '1px solid black', // Agregar bordes de 1px en cada celda
                                    textAlign: 'center', // Centrar el contenido de la celda
                                    verticalAlign: 'middle',
                                }}>
                                    <th style={{
                                        fontSize: '13px', border: '1px solid black', // Agregar bordes de 1px en cada celda
                                        textAlign: 'center', // Centrar el contenido de la celda
                                        verticalAlign: 'middle',
                                    }}>Numero</th>
                                    <th >Agrupaciones Politicas</th>
                                    <th>  Listas </th>

                                    <th style={{
                                        fontSize: '13px', border: '1px solid black', // Agregar bordes de 1px en cada celda
                                        textAlign: 'center', // Centrar el contenido de la celda
                                        verticalAlign: 'middle',
                                    }}>
                                        Gobernador<br />
                                        Vicegobernador
                                    </th>
                                    <th style={{
                                        fontSize: '13px', border: '1px solid black', // Agregar bordes de 1px en cada celda
                                        textAlign: 'center', // Centrar el contenido de la celda
                                        verticalAlign: 'middle',
                                    }}>Senador <br />Provincial</th>

                                    <th style={{
                                        fontSize: '13px', border: '1px solid black', // Agregar bordes de 1px en cada celda
                                        textAlign: 'center', // Centrar el contenido de la celda
                                        verticalAlign: 'middle',
                                    }}>
                                        Intendente<br />
                                        Concejal
                                    </th>
                                </tr>
                            </thead>


                            <tbody>{renderTable()}</tbody>
                        </table>
                        <br></br>
                        <button type='submit'>CARGAR ACTA</button>
                    </form>

                    <Modal show={showModal} onHide={handleModalClose} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmación de carga de acta</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table striped bordered hover>
                                <thead >
                                    <tr style={{
                                        fontSize: '10px'
                                    }}>


                                        <th></th>

                                        <th style={{
                                            fontSize: '10px'
                                        }}>
                                            Gobernador<br />
                                            Vicegobernador
                                        </th>
                                        <th style={{
                                            fontSize: '10px'
                                        }}>Senador <br />Provincial</th>


                                        <th style={{
                                            fontSize: '10px'
                                        }}>
                                            Intendente<br />
                                            Concejal
                                        </th>
                                    </tr>
                                </thead>

                                <tbody >
                                    {dataModal.map((rowData, index) => (
                                        <tr key={index} style={{ fontSize: 12 }}>
                                            <td >{rowData.listaNombre}</td>
                                            <td>{rowData.votos[0].votos}</td>
                                            <td>{rowData.votos[1].votos}</td>
                                            <td>{rowData.votos[2].votos}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="warning" onClick={handleModalClose}>Cancelar</Button>
                            <Button variant="success" onClick={handleActualSubmit}>Confirmar</Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
                <br></br>
                <p></p>
            </Row>
        </Container>
    );
}
