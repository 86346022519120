import React, { createContext, useState, useEffect } from 'react';
import { defineAbility } from '@casl/ability';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const logged = localStorage.getItem('isLoggedIn') === 'true'

  const [isLoggedIn, setIsLoggedIn] = useState(
    logged


  );
  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token_2')
    localStorage.removeItem('token_2_rol')

    setIsLoggedIn(false);
  };

  const [ability, setAbility] = useState(null);
  const [rol, setPerfil] = useState(null);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    console.log(rol)
    setAbility( defineAbility((can, cannot) => {
      if (rol === 'ADMIN') {
        console.log("ROL: ", rol)
        can('borrar', 'Votos');
        can('cargar', 'Votos');
      } else {
        console.log("OPERADOR: ",rol)
        can('cargar', 'Votos');
      }
    }))
  }, [rol]);


 
  console.log("AAAAAAAA")
  console.log(ability)
  return (
    <AuthContext.Provider value={{ ability, isLoggedIn, login, logout, setPerfil }}>
      {children}
    </AuthContext.Provider>
  );
};
