import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Container, Form } from 'react-bootstrap';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import loadingGif from '../css/giphy.gif'; // Replace 'path/to/loading.gif' with the actual path to your loading gif file.
import Sidebar from '../components/Sidebar';
import Select from 'react-select';
import { Nativos } from '../components/Nativos';
import { Migrantes } from '../components/Migrantes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../css/Home.css';

import 'react-toastify/dist/ReactToastify.css';

//const BASE_URL = 'http://localhost:3006';
const BASE_URL = 'https://api.bdatosgeneral.xyz/carga'
const Home = () => {


  const navigate = useNavigate();
  const token = localStorage.getItem('token_2');
  const config = {
    headers: { 'Authorization': `Bearer ${token}` }
  };
  const [selectedMesa, setSelectedMesa] = useState(null);
  const [mesaOptions, setMesaOptions] = useState([]);
  const [mesaInfo, setMesaInfo] = useState(null);
  const [cantidadVotos, setCantidadVotos] = useState('')
  const [loading, setLoading] = useState(false); // Variable de estado para indicar si los datos están cargando

  // Obtener opciones del select desde el endpoint
  const loadMesas = () => {
    axios.get(BASE_URL + '/mesas', config)
      .then((response) => {
        // Los datos del endpoint deben estar en un formato adecuado para el select
        const options = response.data.mesas.map((mesa) => ({
          value: mesa.MesaID, // Cambia "id" por el campo que representa el valor único de cada opción
          label: 'Circuito: ' + mesa.CircuitoID + ' - Mesa Nº ' + mesa.NumeroMesa // Cambia "nombre" por el campo que representa el texto a mostrar en el select
        }));
        setMesaOptions(options);
      })
      .catch((error) => {
        handleApiError(error);
      });
  }


  const [refreshInterval, setRefreshInterval] = useState(1);

  useEffect(() => {
    loadMesas()
    const interval = setInterval(() => {
      loadMesas()
      console.log("actualizando mesas")
    }, refreshInterval * 60 * 1000); // Convertir minutos a milisegundos

    return () => clearInterval(interval);
  }, [refreshInterval]);


  const cargarOtraMesa = () => {

    setMesaInfo(null)
    setSelectedMesa(null)
    loadMesas()
  }

  const handleLoadMesaInfo = () => {
    // Aquí realizamos la solicitud HTTP al endpoint con axios para obtener la información de la mesa seleccionada
    // Indicar que los datos están cargando
    setCantidadVotos('')
    if (selectedMesa) {
      setLoading(true);
      axios.get(BASE_URL + `/mesas/${selectedMesa.value}`, config)
        .then((response) => {

          setMesaInfo(response.data.mesa[0]);
          setLoading(false);
        })
        .catch((error) => {
          handleApiError(error);
        });
    } else {
      alert("Tenes que elegir una mesa")
    }
  };

  const handleApiError = (error) => {
    if (error.message === 'Network Error') {
      // Handle the connection refused error here
      toast.error('Sin conexion con el servidor', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error('Connection refused. The server may not be running or is unreachable.');
    } else {


      switch (error.response && error.response.status) {
        case 401:
          navigate('/login');
          break;
        case 403:
          toast.error('Acceso denegado', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        case 500:
          toast.error('Error interno del servidor ' + error.mensaje, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        default:
          console.error(error);
          break;
      }
    }
  };


  return (
    <>
      <Sidebar />
      <br></br>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container fluid>

        <Row>

          <Col md={6} xs={6} className="text-center">
            <Card>
              <Card.Header> Elegí la escuela</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={12} xs={12}>
                    <Select
                      options={mesaOptions}

                      value={selectedMesa}
                      onChange={(option) => setSelectedMesa(option)}
                      placeholder="Selecciona una mesa"
                    />
                    <br></br>

                    <Button onClick={handleLoadMesaInfo}>Elegir</Button>

                    <br></br>

                  </Col>

                </Row>

              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header> Información del Acta</Card.Header>
              <Card.Body>
                <Row>

                  <Col md={6} xs={12}>
                    {loading && <img style={{ width: 180, height: 180 }} src={loadingGif} alt="Loading" />}

                    {mesaInfo && !loading && (
                      <Row>

                        <p></p>
                        <Row>
                          <Col>
                        <p><b>Escuela:</b> {mesaInfo.Establecimiento}</p>
                        </Col>
                        </Row>
                        <Row>
                          <Col>
                          <p><b>Circuito:</b> {mesaInfo.NumeroCircuito}</p>

                          </Col>
                          <Col>
                          <p><b>Numero de mesa:</b> {mesaInfo.NumeroMesa}</p>

                          </Col>
                        </Row>
                        <p hidden><b>MesaID:</b> {mesaInfo.MesaID}</p>



                        <Form.Group controlId="votos">
                          <Form.Label style={{ color: ( cantidadVotos === '') ? 'red' : 'green' }}>Cantidad de electores que han votado:</Form.Label>
                          <Form.Control
                            type="number"
                            value={cantidadVotos}
                            onChange={(e) => setCantidadVotos(e.target.value)}
                            style={{ borderColor: (cantidadVotos === '') ? 'red' : 'green' }}
                          />
                        </Form.Group>
                      </Row>)}

                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>

        </Row>




        <br></br>
        <Row>
          <p></p>
          <Col md={12} xs={12}>
            <Card>
              <Card.Header>
              {mesaInfo && `Acta: ${mesaInfo.ActaID} - Mesa: ${mesaInfo.Tipo}`}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col></Col>
                  <Col>
                {mesaInfo && (
                  <>
                    {mesaInfo && mesaInfo.Tipo === 'NATIVOS' && <Nativos votos={cantidadVotos} reiniciar={cargarOtraMesa} mesa={mesaInfo.MesaID} acta={mesaInfo.ActaID} />}
                    {mesaInfo && mesaInfo.Tipo === 'MIGRANTES' && <Migrantes votos={cantidadVotos} reiniciar={cargarOtraMesa} mesa={mesaInfo.MesaID} acta={mesaInfo.ActaID} />}
                  </>
                )}
                </Col>
                <Col></Col>
                </Row>
              </Card.Body>
            </Card>

          </Col>

        </Row>
      </Container>
    </>
  );
};

export default Home;
